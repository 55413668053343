<template>
	<el-drawer
		:close-on-press-escape="false"
		:wrapperClosable="false"
		title="银行流水导入"
		:visible.sync="drawer_"
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="70%"
		@opened="open"
		@close="
			() => {
				$refs['ruleForm'].resetFields();
				Object.assign($data, $options.data());
				$emit('closeVisible');
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-form
					:model="ruleForm"
					status-icon
					ref="ruleForm"
					class="demo-ruleForm"
					label-position="rigth"
					label-width="200px"
				>
					<el-form-item
						id="v-step-0"
						label="交易流水涉及银行:"
						:rules="[
							{
								required: true,
								message: '交易流水涉及银行不能为空',
							},
						]"
						prop="transactionBank"
					>
						<el-input
							placeholder="请输入交易流水涉及银行"
							v-model="ruleForm.transactionBank"
							style="width: 86%"
						></el-input>
					</el-form-item>
					<el-form-item
						label="模板类型:"
						prop="templateType"
						:rules="[{ required: true, message: '请选择模板类型' }]"
					>
						<el-radio
							id="v-step-1"
							v-for="item in [
								{
									label: '贷方/借方发生额，金额两字段',
									value: 1,
								},
								{ label: '交易金额，金额单个字段', value: 2 },
							]"
							v-model="ruleForm.templateType"
							:label="item.value"
							:key="item.value"
							>{{ item.label }}</el-radio
						>
					</el-form-item>
					<el-form-item
						label="账户信息类型:"
						prop="accountInfoType"
						:rules="[
							{ required: true, message: '请选择账户信息类型' },
						]"
					>
						<el-radio
							id="v-step-2"
							v-for="item in [
								{ label: '单个账号信息', value: 1 },
								{ label: '收付款帐户信息分开的情况', value: 2 },
							]"
							v-model="ruleForm.accountInfoType"
							:label="item.value"
							:key="item.value"
							>{{ item.label }}</el-radio
						>
					</el-form-item>
					<el-form-item
						label="Excel除列头数据开始行:"
						:rules="[
							{
								required: true,
								message: '数据开始行不能为空',
							},
						]"
						prop="startRowNum"
						style="color: #606266"
					>
						数据从第
						<el-input
							id="v-step-3"
							style="width: 100px"
							placeholder="请输入"
							v-model.trim="ruleForm.startRowNum"
						></el-input>
						行开始
					</el-form-item>

					<p class="tips">
						提示：
						1、不输入将要导入Excel的表头列名，则按标准模板表头列名获取Excel中数据
					</p>
					<p class="tips" style="padding-left: 45px">
						2、excel中若无对方账号、摘要和备注，则无需填写对应的表头列名
					</p>
					<div class="diy-table">
						<div>
							<span>标准模板表头列名</span>
							<span> excel表头列名</span>
						</div>
						<div>
							<span>交易日期</span>
							<span
								><input
									v-model="ruleForm.transactionDate"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.templateType == 1">
							<span>贷方发生额</span>
							<span
								><input
									v-model="ruleForm.lenderAmountName"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.templateType == 1">
							<span>借方发生额</span>
							<span
								><input
									v-model="ruleForm.debitAmountName"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.accountInfoType == 1">
							<span>对方户名</span>
							<span
								><input
									v-model="ruleForm.accountName"
									type="text"
							/></span>
						</div>

						<div v-if="ruleForm.accountInfoType == 1">
							<span>对方账号</span>
							<span
								><input
									v-model="ruleForm.accountNo"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.accountInfoType == 2">
							<span>付款人户名</span>
							<span
								><input
									v-model="ruleForm.debitAccountName"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.accountInfoType == 2">
							<span>付款人账号</span>
							<span
								><input
									v-model="ruleForm.debitAccountNo"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.accountInfoType == 2">
							<span>收款人户名</span>
							<span
								><input
									v-model="ruleForm.lenderAccountName"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.accountInfoType == 2">
							<span>收款人账号</span>
							<span
								><input
									v-model="ruleForm.lenderAccountNo"
									type="text"
							/></span>
						</div>
						<div v-if="ruleForm.templateType == 2">
							<span>交易金额</span>
							<span
								><input
									v-model="ruleForm.transactionAmountName"
									type="text"
							/></span>
						</div>
						<div>
							<span>余额</span>
							<span
								><input
									v-model="ruleForm.balanceAmountName"
									type="text"
							/></span>
						</div>
						<div>
							<span>摘要</span>
							<span
								><input v-model="ruleForm.summary" type="text"
							/></span>
						</div>
						<div>
							<span>备注</span>
							<span
								><input
									v-model.trim="ruleForm.remark"
									type="text"
							/></span>
						</div>
					</div>
					<el-form-item label="请选择文件:">
						<el-upload
							class="upload-demo"
							ref="upload"
							action="Fake Action"
							:file-list="fileList"
							:auto-upload="false"
							:on-change="handleChange"
							name="files"
						>
							<el-button
								id="v-step-4"
								slot="trigger"
								size="small"
								type="primary"
								>选取文件</el-button
							>
						</el-upload>
					</el-form-item>
				</el-form>

				<div class="demo-drawer__footer">
					<el-button
						id="v-step-5"
						type="primary"
						@click="postRecommendClientSave"
						>导 入</el-button
					>

					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
			<v-tour :options="myOptions" name="myTour" :steps="steps"></v-tour>
		</div>
	</el-drawer>
</template>

<script>
import { importBankFlow } from '@/api/index.js';
export default {
	name: 'infoDrawer',
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
		},
		parentRuleForm: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			ruleForm: {
				transactionBank: '',
				templateType: 1,
				accountInfoType: 1,
				startRowNum: '',
				transactionDate: '',
				debitAccountName: '',
				debitAccountNo: '',
				lenderAccountName: '',
				lenderAccountNo: '',
				transactionAmountName: '',
				balanceAmountName: '',
				summary: '',
				remark: '',
				accountName: '',
				accountNo: '',
				lenderAmountName: '',
				debitAmountName: '',
				file: {},
			},
			fileList: [],
			steps: [
				{
					target: '#v-step-0',
					content: `填写流水对应的银行名称`,
				},
				{
					target: '#v-step-1',
					content: '选择模板对应的类型',
				},
				{
					target: '#v-step-2',
					content: '选择账户信息类型',
				},
				{
					target: '#v-step-3',
					content: '填写数据开始的行数',
				},
				{
					target: '#v-step-4',
					content: '选择数据文件',
				},
				{
					target: '#v-step-5',
					content: '点击导入',
					params: {
						placement: 'top',
					},
				},
			],
			myOptions: {
				useKeyboardNavigation: false, //不使用←、→和ESC键来导航tour
				startTimeout: 1000, //1秒后执行
				labels: {
					buttonSkip: '跳过',
					buttonPrevious: '上一步',
					buttonNext: '下一步',
					buttonStop: '关闭',
				},
			},
		};
	},
	methods: {
		postRecommendClientSave() {
			// this.ruleForm.file.append('file', this.fileList[0].raw);
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					let formData = new FormData();
					formData.append(
						'transactionBank',
						this.ruleForm.transactionBank
					);
					formData.append('templateType', this.ruleForm.templateType);
					formData.append('startRowNum', this.ruleForm.startRowNum);
					formData.append(
						'transactionDate',
						this.ruleForm.transactionDate
					);
					formData.append(
						'debitAccountName',
						this.ruleForm.debitAccountName
					);
					formData.append(
						'debitAccountNo',
						this.ruleForm.debitAccountNo
					);
					formData.append(
						'lenderAccountName',
						this.ruleForm.lenderAccountName
					);
					formData.append(
						'lenderAccountNo',
						this.ruleForm.lenderAccountNo
					);
					formData.append(
						'transactionAmountName',
						this.ruleForm.transactionAmountName
					);
					formData.append(
						'balanceAmountName',
						this.ruleForm.balanceAmountName
					);
					formData.append('summary', this.ruleForm.summary);
					formData.append('remark', this.ruleForm.remark);
					formData.append('accountName', this.ruleForm.accountName);
					formData.append('accountNo', this.ruleForm.accountNo);
					formData.append(
						'lenderAmountName',
						this.ruleForm.lenderAmountName
					);
					formData.append(
						'debitAmountName',
						this.ruleForm.debitAmountName
					);
					formData.append(
						'accountInfoType',
						this.ruleForm.accountInfoType
					);
					formData.append(
						'cooperationPresidentId',
						this.$store.state.userInfo.userVo.cooperationPresidentId
					);
					formData.append('file', this.ruleForm.file);
					formData.append(
						'clientName',
						this.parentRuleForm.clientName
					);

					const res = await importBankFlow(formData);
					if (res.returncode == 0) {
						this.$message({
							message: '导入成功',
							type: 'success',
						});
						this.$emit('closeVisibleSuccess');
					}
				}
			});
		},
		handleChange(file, fileList) {
			if (fileList.length > 0) {
				this.fileList = [fileList[fileList.length - 1]];
				this.ruleForm.file = file.raw;
			}
		},
		open() {
			this.$nextTick(() => {
				if (!localStorage.getItem('A')) {
					localStorage.setItem('A', 1);
					this.$tours['myTour'].start();
				}
			});
		},
	},
	created() {},
	computed: {
		drawer_: {
			get() {
				return this.dialogVisible;
			},
			set(v) {
				this.$emit('update:dialogVisible', v);
			},
		},
	},
	watch: {
		'ruleForm.templateType'() {
			this.ruleForm.debitAccountName = '';
			this.ruleForm.debitAccountNo = '';
			this.ruleForm.lenderAccountName = '';
			this.ruleForm.lenderAccountNo = '';
			this.ruleForm.transactionAmountName = '';
			this.ruleForm.accountName = '';
			this.ruleForm.accountNo = '';
			this.ruleForm.lenderAmountName = '';
			this.ruleForm.debitAmountName = '';
		},
		'ruleForm.accountInfoType'() {
			this.ruleForm.debitAccountName = '';
			this.ruleForm.debitAccountNo = '';
			this.ruleForm.lenderAccountName = '';
			this.ruleForm.lenderAccountNo = '';
			this.ruleForm.transactionAmountName = '';
			this.ruleForm.accountName = '';
			this.ruleForm.accountNo = '';
			this.ruleForm.lenderAmountName = '';
			this.ruleForm.debitAmountName = '';
		},
	},
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 10px;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}
::v-deep .el-drawer__header {
	padding-bottom: 20px;
	border-bottom: 1px solid #dee1e7;
}
::v-deep .el-form-item {
	margin-bottom: 10px;
}
.tips {
	color: red;
	text-align: left;
	line-height: 25px;
	font-size: 13px;
	margin-left: 200px;
}
.diy-table {
	width: 80%;
	margin: 10px auto;
	border: 1px #e3e3e3 solid;
	font-size: 12px;
	div {
		width: 100%;
		display: flex;
		height: 40px;
		border-bottom: 1px solid #e3e3e3;
		box-sizing: border-box;
		span {
			height: 100%;
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			input {
				width: 96%;
				height: 28px;
				border: 1px solid #dbdbdb;
				padding-left: 8px;
			}
			&:last-child {
				border-left: 1px solid #e3e3e3;
			}
		}
		&:last-child {
			border-bottom: none;
		}
		&:first-child {
			font-weight: 800;
			background: #f2f2f2;
		}
	}
}
</style>
